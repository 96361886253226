import React from "react"
import { Link } from 'gatsby'
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from '../components/Layout'
import CTAStart from "../components/CTAStart"
import SEO from "../components/SEO"




const Index = (props) => {
  return (

    <Layout>
      <SEO title={"Home"} />
      <div className="rbs-section">
        <div className="rbs-box-a bg-rbs-red text-white">
          <div className="content">
            <h2 className="title-xl mb-16">
              Branding<br />
              for Startups
            </h2>
            <p className="body-xl mb-16 md:w-3/4">
              Helping ambitious founders gain a competitive advantage through launching outstanding visual identities in weeks rather than months.
            </p>
            <Link to="/start" className="body-xl link-arrow">Start Now</Link>
          </div>
        </div>
        <div className="bg-rbs-black"></div>
      </div>

      <div className="rbs-section">
        <div className="rbs-bg bg-02"></div>
        <div className="rbs-box-a">
          <div className="content">
            <h2 className="title-l mb-16">
              Unique Formula
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-3 gap-16 mb-16">
              <div>
                <h3 className="title-s">Proven Method</h3>
                <p className="body-l text-rbs-black-50">A battle-tested original process</p>
              </div>
              <div>
                <h3 className="title-s">Focused Effort</h3>
                <p className="body-l text-rbs-black-50">Designed for high performance teams</p>
              </div>
              <div>
                <h3 className="title-s">Remote</h3>
                <p className="body-l text-rbs-black-50">Crafted for a remote experience</p>
              </div>
            </div>
            <Link to="/method" className="body-l link-arrow">Our Method</Link>
          </div>
        </div>
      </div>



      <div className="bg-rbs-black text-white">
        <div className="px-8 py-16 md:px-16 md:py-32">
          <p className="body-xl mb-16">
            Helping to kickstart the brands<br />
            of the next generation stars
            </p>

          <div className="grid grid-cols-3 md:grid-cols-6 gap-4 mb-16">
            <div className="logo-thumb-toggle">
              <img src={'img/logo-01.svg'} className="logo-thumb logo-thumb-a" alt="" />
              <img src={'img/logo-01-b.svg'} className="logo-thumb logo-thumb-b" alt="" />
            </div>
            <div className="logo-thumb-toggle">
              <img src={'img/logo-02.svg'} className="logo-thumb logo-thumb-a" alt="" />
              <img src={'img/logo-02-b.svg'} className="logo-thumb logo-thumb-b" alt="" />
            </div>
            <div className="logo-thumb-toggle">
              <img src={'img/logo-03.svg'} className="logo-thumb logo-thumb-a" alt="" />
              <img src={'img/logo-03-b.svg'} className="logo-thumb logo-thumb-b" alt="" />
            </div>
            <div className="logo-thumb-toggle">
              <img src={'img/logo-04.svg'} className="logo-thumb logo-thumb-a" alt="" />
              <img src={'img/logo-04-b.svg'} className="logo-thumb logo-thumb-b" alt="" />
            </div>
            <div className="logo-thumb-toggle">
              <img src={'img/logo-05.svg'} className="logo-thumb logo-thumb-a" alt="" />
              <img src={'img/logo-05-b.svg'} className="logo-thumb logo-thumb-b" alt="" />
            </div>
            <div className="logo-thumb-toggle">
              <img src={'img/logo-07.svg'} className="logo-thumb logo-thumb-a" alt="" />
              <img src={'img/logo-07-b.svg'} className="logo-thumb logo-thumb-b" alt="" />
            </div>
          </div>

          <Link to="/cases" className="body-l text-white link-arrow opacity-50">View Cases</Link>

        </div>
      </div>



      <div className="rbs-section">
        <div className="rbs-box-a">
          <div className="content">
            <h2 className="title-l mb-16">
              Focused Process
            </h2>
            <div className="grid grid-cols-3 gap-16 mb-16">
              <div>
                <h3 className="title-s">Pre-Sprint</h3>
              </div>
              <div className="col-span-2">
                <p className="body-l text-rbs-black-50">
                  Setting the stage<br />
                  Brand brief
                </p>
              </div>

              <div>
                <h3 className="title-s">Week 1</h3>
              </div>
              <div className="col-span-2">
                <p className="body-l text-rbs-black-50">
                  Strategy<br />
                  Brand exploration
                </p>
              </div>

              <div>
                <h3 className="title-s">Week 2</h3>
              </div>
              <div className="col-span-2">
                <p className="body-l text-rbs-black-50">
                  Brand focus<br />
                  Applications<br />
                  Toolbox
                </p>
              </div>

              <div>
                <h3 className="title-s">Post-Sprint</h3>
              </div>
              <div className="col-span-2">
                <p className="body-l text-rbs-black-50">
                  Final polish<br />
                Documentation<br />
                Support
                </p>
              </div>
            </div>
            <Link to="/process" className="body-l link-arrow">Show Process</Link>
          </div>
        </div>
        <div className="rbs-bg bg-04"></div>

      </div>

      <div className="rbs-section">
        <div className="rbs-bg bg-05"></div>
        <div className="rbs-box-a">
          <div className="content">
            <h2 className="title-l mb-16">
              Get What You Need
            </h2>
            <div className="grid grid-cols-3 gap-16 mb-16">
              <div>
                <h3 className="title-s">Strategy</h3>
                <p className="body-l text-rbs-black-50">
                  <Link to="/results/#brand-strategy">Brand concept</Link><br />
                  <Link to="/results/#brand-strategy">Design principles</Link>
                </p>
              </div>
              <div>
                <h3 className="title-s">Visual Identity</h3>
                <p className="body-l text-rbs-black-50">
                  <Link to="/results/#visual-identity">Logotype</Link><br />
                  <Link to="/results/#visual-identity">Typography</Link><br />
                  <Link to="/results/#visual-identity">Color</Link><br />
                  <Link to="/results/#visual-identity">Imagery</Link>
                </p>
              </div>
              <div>
                <h3 className="title-s">Brand Applications</h3>
                <p className="body-l text-rbs-black-50">
                  <Link to="/results/#brand-applications">Print collateral</Link><br />
                  <Link to="/results/#brand-applications">Social media</Link><br />
                  <Link to="/results/#brand-applications">Templates</Link><br />
                  <Link to="/results/#brand-applications">Branded items</Link>
                </p>
              </div>
            </div>
            <Link to="/results" className="body-l link-arrow">View Results</Link>
          </div>
        </div>
      </div>





      <div className="case-bg-01 p-16 pt-32 md:p-32">
        <h2 className="title-l text-white mb-16">Case Study</h2>
        <div className="text-white case-grid">
          <div>
            <h3 className="title">taju.space</h3>
            <p className="body-xl opacity-50">Personal multimedia space</p>
          </div>
          <div className="col-span-2">
            <p className="body-l opacity-50">taju.space is a personalised multimedia room that fits in your back yard. A truly innovative offering in a fast growing market segment fueled by the C-19 crisis. We helped taju.space kickstart their brand with the remote brand sprint. The fresh visual look, and a simple landingpage enabled our client to launch the taju sub-brand marketing campaign, test the reaction of their target audience and get the first customers interested, even before actually building the first unit.</p>
          </div>
        </div>
        <div className="relative w-full mx-auto">
          <Img imgStyle={{ objectFit: 'contain' }} fluid={props.data.case01.childImageSharp.fluid} />
        </div>


        <div className="text-center mt-16">
          <Link to="/cases" className="text-white opacity-50 body-l link-arrow">View more cases</Link>
        </div>

      </div>





      <CTAStart />

    </Layout>
  )
}


export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    case01: file(relativePath: { eq: "case-01.png" }) {
      ...fluidImage
    }
  }
`

export default Index;